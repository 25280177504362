import React, { useEffect, useState } from "react";
// import Header from "../../Newcomponent/Header";
import Account_Header from "../../Newcomponent/Account_Header";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../../../core/service/detail";
import { postMethod } from "../../../core/service/common.api";
import Footernew from "../../footer_buttom";
import { Grid, Paper, Container } from "@mui/material";
import { setAuthorization } from "../../../core/service/axios";
import { Button } from "semantic-ui-react";
import { Checkbox } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import SidebarNew from "./SidebarNew";
import { ValidationError } from "yup";
import moment from "moment";
import Pagination from "react-js-pagination";
import useStateref from "react-usestateref";

function Home() {
  const value = {
    Subject: "",
    Category: "Choose Category",
    text: "",
  };

  const [Formdata, setFormdata] = useState(value);

  const [SubjectErr, setSubjectErr] = useState(value);
  const [CategoryErr, setCategoryErr] = useState(value);
  const [textErr, settextErr] = useState(value);

  const [formErr, setformErr] = useState("");

  const [user, setuser] = useState([]);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [currentPage, setCurrentPage, currentPageref] = useStateref(1);
  const [totalPage, setTotalpages] = useState(0);

  const { Subject, Category, text } = Formdata;

  const getItem = (e) => {
    var { name, value } = e.target;
    let check = { ...Formdata, ...{ [name]: value } };
    setFormdata(check);
  };

  const recordPerPage = 5;
  const totalRecords = 15;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    console.log("-=-=-=-=-=-=-=-=-=-=enter");
    viewData(pageNumber);
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    viewData(1);
  }, []);

  const validate = async (condition) => {
    var error = {};
    if (condition.Subject == "") {
      error.Subject = "Subject is a required field";
      setSubjectErr(true);
    } else if (condition.Subject.length < 5) {
      error.Subject = "Minimum 5 Characters only allowed ";
      setSubjectErr(true);
    } else if (condition.Subject.length > 50) {
      error.Subject = "Maximum 50 Characters only allowed ";
      setSubjectErr(true);
    } else {
      setSubjectErr(false);
    }

    if (condition.text == "") {
      error.text = "Message is a required field";
      settextErr(true);
    } else if (condition.text.length <= 10) {
      error.text = "Minimum 10 Characters only allowed ";
      settextErr(true);
    } else if (condition.text.length > 250) {
      error.text = "Maximum 250 Characters only allowed ";
      settextErr(true);
    } else {
      settextErr(false);
    }

    if (condition.Category == "Choose Category") {
      error.Category = "Category is required";
      setCategoryErr(true);
    }
    setformErr(error);
  };

  const submit = async () => {
    validate(Formdata);
    // return false;
    if (
      Formdata.Subject != "" &&
      Formdata.Subject.length > 5 &&
      Formdata.Subject.length < 50 &&
      Formdata.Category != "Choose Category" &&
      Formdata.text != "" &&
      Formdata.text.length >= 10 &&
      Formdata.text.length <= 250
    ) {
      toast.success(
        "Your ticket created successfully, Please wait for admin reply"
      );
      var data = {
        apiUrl: apiService.createdSupport,
        payload: Formdata,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      const obj = {
        Subject: "",
        Category: "Choose Category",
        text: "",
      };
      setFormdata(obj);
      viewData(1);
    }
  };
  const viewData = async (page) => {
    try {
      var api = {
        apiUrl: apiService.findedSupport,
        payload: {
          page: page,
          PerPage: pageRange,
        },
      };
      var view = await postMethod(api);
      if (view.status) {
        setuser(view.data.data);
        setTotalpages(view.data.total);
        console.log(view.data.total);
      }
    } catch (error) { }
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg  bg-cover onlywhitee new_login_bb">
        <Account_Header />
        <Container maxWidth="xl" className="container-lg">
          <Grid container spacing={2} justifyContent={"center"}>
            {/* Item for xs (extra small) screens */}
            <Grid item xs={12} sm={12} md={8} lg={3} xl={3}>
              <SidebarNew />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12} sm={12} md={10} lg={7} xl={7}>
                  <div className="card_logoki pading_cardd">
                    <div className="step-2 ">
                      <div className="form_content">
                        <h1 className="mb-3">Support Ticket</h1>
                      </div>
                      <div class="form_login_section p-0">
                        <div class="form register_login p-0">
                          <form className="form_pading_s">
                            <div class="form-group">
                              <label>Subject</label>
                              <input
                                type="email"
                                class="form-control"
                                id="exampleInputPassword1"
                                placeholder="Enter subject"
                                name="Subject"
                                maxLength={50}
                                value={Subject}
                                onChange={getItem}
                              />
                              <div className="alert_red">
                                {SubjectErr == true ? (
                                  <p style={{ color: "red", "text-align": "-webkit-match-parentc" }}>
                                    {formErr.Subject}{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Category</label>
                              <select
                                class="form-control"
                                name="Category"
                                value={Category}
                                onChange={getItem}
                              >
                                <option selected>Choose Category</option>
                                <option selected>Spot</option>
                                <option selected>P2p</option>
                                <option selected>Launchpad</option>
                                <option selected>Staking</option>
                                <option selected>Settings</option>
                              </select>
                              <div className="alert_red">
                                {CategoryErr == true ? (
                                  <p style={{ color: "red", "text-align": "-webkit-match-parentc" }}>{formErr.Category}</p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Message</label>
                              <input
                                type="email"
                                class="form-control"
                                id="exampleInputPassword1"
                                placeholder="Enter Message"
                                maxLength={250}
                                name="text"
                                value={text}
                                onChange={getItem}
                              />
                              <div className="alert_red">
                                {textErr == true ? (
                                  <p style={{ color: "red", "text-align": "-webkit-match-parentc" }}>{formErr.text} </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                          </form>

                          {buttonLoader == false ? (
                            <button
                              type="button"
                              class="btn btn-primary w-100"
                              onClick={submit}
                            >
                              Submit
                            </button>
                          ) : (
                            <button type="button" class="btn btn-primary w-100">
                              loading...
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="table_section">
                    <h3 className="font_color">Ticket History</h3>
                    <div className="table_responsive">
                      <div class="custom-table">
                        <div class="table-row header">
                          <div class="table-cell">Subject</div>
                          <div class="table-cell">Message</div>
                          <div class="table-cell">Category</div>
                          <div class="table-cell">Date</div>
                        </div>

                        {user.length > 0 ? (
                          user.map((data, i) => {
                            return (
                              <div class="table-row border_table_row">
                                <div class="table-cell">
                                  <div class="table-cell">
                                    <div className="data_inner">
                                      {data.subject}
                                    </div>
                                  </div>
                                </div>
                                <div class="table-cell table_message">
                                  <div className="data_inner">
                                    {data.message}
                                  </div>
                                </div>
                                <div class="table-cell">
                                  <div className="data_inner">
                                    {data.category}
                                  </div>
                                </div>
                                <div class="table-cell">
                                  <div className="data_inner">
                                    {moment(data.created_at).format("L")}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div class="table-cell justify-center">
                            <div className="data_inner">No Data found</div>
                          </div>
                        )}
                        {user.length > 0 ?
                          <Pagination
                            itemClass="page-item" // add it for bootstrap 4
                            linkClass="page-link" // add it for bootstrap 4
                            activePage={currentPageref.current}
                            itemsCountPerPage={recordPerPage}
                            totalItemsCount={totalPage}
                            pageRangeDisplayed={pageRange}
                            onChange={handlePageChange}
                          /> : ""}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Your other components and content */}
        </Container>
      </main>
    </div>
  );
}

export default Home;
