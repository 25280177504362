const service = {
  signup: "users/register",
  emailotpverify: "users/emailotpverify",
  resendCode: "users/resendCode",
  sign_in: "users/signin",
  signin: "users/sign_in",
  email_otp: "users/email_otp",
  email_verify: "users/signup_value",
  changepswdlink: "users/changepswdlink",
  verifyForgotpasslink: "users/verifyForgotpasslink",
  resetpassword: "users/forgotpassword",
  profileUpdate: "/user/profileAdd",
  update_profile: "users/update_profile",
  getuserProfile: "users/getUserProfile",
  getUserDetails: "users/getUserDetails",
  getuserDetails: "kyc/getuserDetails",
  changeEmail: "users/changeEmail",
  changeEmailverify: "users/changeEmailverify",
  editProfile: "users/editProfile",
  getnotification: "users/getnotification",
  getSessionHisotry: "users/getSessionHisotry",
  getKYC: "kyc/getkyc",
  updateKycproof: "kyc/updateKycproof",
  getKycDeatils: "kyc/getKYCDetails",
  updateBankdetails: "users/updateBankdetails",
  savekyc_id: "kyc/updatekyc",
  updateKyc: "kyc/kycupdate",
  Bankdetails: "users/bankdetails",
  Getbankdetails: "users/Getbankdetails",
  getKycDeatil2: "kyc/getKycDeatils",
  generateAddress: "address/getUserAddress",
  submitWithdraw: "withdraw/withdraw_submit",
  confirmWithdraw: "withdraw/withdraw_user_action",
  bankwire: "users/getbankwire",
  fiatdepositSubmit: "withdraw/fiatdeposit_submit",
  createdSupport: "support/create_ticket",
  findedSupport: "support/ticket_list",
  getP2Pcurrency: "p2p/p2p_currencies",
  p2pOrder: "p2p/postAddOrder",
  p2pGetOrder: "p2p/getAllp2pOrder/",
  getp2pOrder: "p2p/getp2pOrder/",
  p2pchat: "p2p/p2p_chat/",
  getp2pchat: "p2p/getp2pchat/",
  buyer_confirm: "p2p/buyer_confirm_payment/",
  seller_confirm: "p2p/seller_coin_confirm",
  buyer_cancel: "p2p/buyer_cancel/",
  seller_cancel: "p2p/seller_cancel",
  submitfiatWithdraw: "withdraw/fiatwithdraw_submit",
  p2pOrders: "p2p/myOrders",
  getnotifyOrder: "p2p/getnotifyOrder/",

  getCurrpairData: "trade/getCurrpairData",
  getUserBalance: "users/getUserBalance",
  getUserBalance_dashboard:"users/getUserBalance_dashboard",
  getparUserBalance: "trade/getparUserBalance",
  orderPlaceapi: "orderPlaceApi/orderPlace",
  addBank: "/user/addBank",
  getBank: "/user/getBank",
  defaultBanks: "users/defaultBankChoose",
  deletbank: "users/deletbankDetails",
  savekyc_address: "/kyc/savekyc_Address",
  userLoginHistory: "/user/getLoginHistory",
  p2pCompareAPI: "p2p/cryptoCompare",
  buy_sell_Express: "p2p/buy_sell_Express_api",
  changePassword: "users/change_password",
  getTfaDetials: "users/tfaDetials",
  changeTfaStatus: "users/changeTfaStatus",
  tfaVerify: "users/tfa_login",
  getBalance: "/user/getAllBalances",
  checkwallet: "/checkwallet",
  transactionHistory: "/withdraw/transactionHistory",
  resetPass: "/user/retetPassword",
  forgotepassword: "/user/forgotepassword",
  notifications: "p2p/notifications",
  p2pMyorders: "/p2p/p2pMyorders",
  p2pExpress: "/p2p/p2pExpressOrders",
  getSupportData: "/adminapi/support_category_list",
  createSupport: "/user/create_ticket",
  get_Ticket: "/user/get_Ticket",
  updateTickets: "/user/updateTicket",
  viewChat: "/user/viewChat",
  closeTicket: "/user/ticketClosed",
  pairbycurrency: "adminapi/tradepair/viewbycurrency",
  fetch_tickers: "users/fetch_tickers",
  withdraw_history: "withdraw/withdraw_history",
  deposit_history: "withdraw/get_user_deposit",
  getActiveOrders: "users/getActiveOrders",
  marketTrades: "marketTrades/getMarketTrades",
  tradeHistory: "users/tradeHistory",
  getCancelOrders: "users/getCancelOrders",
  getCurrency: "users/getCurrency",
  getCurrencywith: "users/getcurrencywith",
  getSiteDatas: "adminapi/get_sitedata",
  getStatkingDetails: "staking/getStatkingDetails",
  getUserBalanceAll: "withdraw/getUserBalanceStaking",
  confirmStaking: "staking/confirmStaking_user",
  getAllstakingHistory: "staking/getAllstakingHistory",
  getStakingTotal: "staking/getStakingTotal",
  claimNowapi: "staking/claimNowapi",
  submitForm: "launchPad/submitForm",
  getAllLaunchpad: "launchPad/getAllLaunchpad",
  getOneLaunchpad: "launchPad/getOneLaunchpad",
  getTokenDetails: "launchPad/getTokenDetails",
  launchPadcurrency: "launchPad/launchPadcurrency",
  getCurrencyConvertion: "launchPad/getCurrencyConvertion",
  tokenPurchase: "launchPad/tokenPurchase",
  lauchPadHistory: "launchPad/lauchPadHistory",
  cancelOrder: "orderCancelApi/cancelOrder",
  claimNowapiFlexible: "staking/claimNowapiFlexible",
  portfolioBalance: "users/portfolio_balance",
  balance_percentage: "users/balance_percentage",
  transfer_balance: "users/transfer_balance",
  getChartValue: "users/getChartValue",
  currency_list: "users/currency_list",
  p2p_confirm_order: "p2p/p2p_confirm_order",
  p2p_confirm_sellorder: "p2p/p2p_confirm_sellorder",
  p2p_confirm_check: "p2p/p2p_confirm_check",
  p2pHistory: "p2p/p2p_history",
  getP2Pbalance: "p2p/p2p_balance",
  allpairs: "adminapi/allpairs",
  getKYCDetails: "kyc/getKYCDetails",
  savebank: "kyc/savekyc",
  raise_dispute: "p2p/dispute_order",
  confirmp2porder: "p2p/p2p_check_confirm",
  fetch_price: "p2p/fetch_price",
  getp2pBalance: "p2p/getp2pBalance",
  viewp2pOrder: "p2p/viewp2pOrder/",
  cancelConfirmBuy: "p2p/cancel_confirm_order",
  cancelConfirmSell: "p2p/cancel_confirmorder_sell",
  readNotify: "p2p/read_notify",
  update_p2p: "p2p/update_order",
  buyer_pay_cancel: "p2p/buyer_pay_cancel",
  p2pProcessOrders: "p2p/processOrders",
  contact: "users/contact_us",
  getUserBalanceSwap: "withdraw/getUserBalance",
  currencyConversion: "swap/currencyConversion",
  swapping: "swap/swapping",
  swappingHistory: "swap/swappingHistory",
  getReward: "users/getReward",
  referralHistory: "users/referralHistory",
  yieldcalculation: "staking/yieldcalculation",
  claimNow_yield: "staking/claimNowapi_yield",
  earningHistory: "users/earningHistory",
  viewOrder_p2p: "p2p/p2p_get_order",

  //FORGOT PASSWORD============
  reset_pwd_otp: "users/reset_pwd_otp",
  verifyForgotcode: "users/verifyForgotcode",
  resetPassword: "users/resetPassword",
  add_fav: "trade/add_fav_pair",
  get_fav: "trade/get_fav",
  pairbyfav: "trade/tradepair/favpair",
  homeCurrency: "users/homeCurrency",


  maxicalculating:"launchPad/maxicalculating",
  LaunchpadtokenPurchase:"launchPad/LaunchpadtokenPurchase",
  getUSDTBalance: "launchPad/getUSDTBalance",
  holding_currency: "launchPad/holding_currency",
  

  //  withdraw

  send_withdraw_otp:"withdraw/send_withdraw_otp",
  getconfirmorderDetails:"p2p/getconfirmorderDetails",

  stakingHistory_fixed: "staking/stakingHistory_fixed",
  stakingHistory_flexible: "staking/stakingHistory_flexible",
  stakingHistory_yield: "staking/stakingHistory_yield"
  
};

export default service;
