import React, { useEffect } from "react";
// import Header from "../../Newcomponent/Header";
import Landing_header from "../../Newcomponent/Landing_header"
import { Link, useNavigate } from "react-router-dom";
import apiService from "../../../core/service/detail";
import { getMethod, postMethod } from "../../../core/service/common.api";
import { Grid, Paper, Container } from "@mui/material";
import { getAuthToken } from "../../../core/lib/localStorage";
import { Dropdown } from "semantic-ui-react";
import useState from "react-usestateref";
import { Button } from "semantic-ui-react";
import Slider from "react-slick";
import { socket } from "../../context/socket";
import Chart from "../../chart/Chart";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Undermaintain from "../../Undermaintain"

function Home() {
  const navigate = useNavigate();
  const [currencylistData, setcurrencylistData] = useState([]);

  useEffect(() => {
    gethomeCurrency();
    getSiteDetails();
  }, []);

  const gethomeCurrency = async () => {
    try {
      var data = {
        apiUrl: apiService.homeCurrency,
      };
      var resp = await getMethod(data);
      if (resp.status == true) {
        setcurrencylistData(resp.Message);
      }
    } catch (error) {}
  };

  var settings = {
    dots: false,
    arrows: false,
    vertical: true,
    infinite: true,
    speed: 0,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  var settingsnew = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  const navbar = (value) => {
    navigate(value);
  };

  const [siteData, setsiteData] = useState({});
  const getSiteDetails = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      if (resp.status == true) {
        setsiteData(resp.data);
        console.log(resp.data, "================-=-=-");
      }
    } catch (error) {}
  };

  return (
    
    <div className="">
      <main className="main-content  bg-cover onlywhitee new_login_bb">
        <Landing_header />
        <section className="banner">
          <div className="container-lg">
            <div className="row">
              <div className="col-lg-6 d-flex justify-content-lg-center align-items-center">
                <div className="bnr">
                  <h1 className="banner-text">
                    Begin Trading with your <br />
                   Favorite Coins
                  </h1>
                  <p className="banner-content">
                    Welcome to Taikonz! A next-gen Blockchain and Crypto
                    {/* <br /> */}
                    Exchange platform that offers trader with an endless
                    {/* <br /> */}
                    assets buy and selling feature with real money.
                  </p>
                  <button
                    className="banner-btn mt-4"
                    onClick={() => navbar("register")}
                  >
                    Get Started
                  </button>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="banner-image">
                  <div className="lightmode-banner-image">
                    <img
                      src={require("../../../img/New_images/taikonx/banner_image.png")}
                      alt="pic"
                      className="img-fluid"
                    />
                  </div>
                  <div className="darkmode-banner-image">
                    <img
                      src={require("../../../img/New_images/taikonx/dark_banner.png")}
                      alt="pic"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="slider">
          <div class="container-lg">
            <Slider {...settingsnew}>
              {currencylistData &&
                currencylistData.map((obj, i) => {
                  return (
                    <div class="slider-list ">
                      <div class="slider-logo porel">
                        <img src={obj.Currency_image} alt="pic" className="imgblur" width="60px" />
                      </div>
                      <div class="slider-logo">
                        <img src={obj.Currency_image} alt="pic" className="posab" width="60px" />
                      </div>
                      <h4 class="slider-heading">{obj.currencyName}</h4>
                      <p class="slider-text">{obj.currencySymbol}</p>
                      <span class="slider-num">{obj.estimatedValueInUSDT}</span>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </section>

        <section className="taikonz">
          <div className="container-lg">
            <div className="row">
              <div className="col-lg-12">
                <div className="y-taikonz-contant">
                  <h1>Why Taikonz?</h1>
                  <p>
                    We build crypto products that are simple,eleggant and
                    secure.
                    <br />
                    Whether you are an individual or an institution,we want to
                    help you <br />
                    buy,sell,and store your bitcoin and certocurrency.
                  </p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="index_2">
                  <div className="index_1">
                    <img
                      src={require("../../../img/New_images/taikonx/bitcoin-icon.png")}
                      class="img-fluid"
                      alt="pic"
                    />
                    <h3>Super Quick Kyc</h3>
                    <p>
                      Swift execution for seizing market <br />
                      opportunitiesSwift execution for seizing market <br />
                      opportunities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="index_2">
                  <div className="index_1">
                    <img
                      src={require("../../../img/New_images/taikonx/bitcoin-icon.png")}
                      class="img-fluid"
                      alt="pic"
                    />
                    <h3>Fast Transactions</h3>
                    <p>
                      Swift execution for seizing market <br />
                      opportunitiesSwift execution for seizing market <br />
                      opportunities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="index_2">
                  <div className="index_1">
                    <img
                      src={require("../../../img/New_images/taikonx/bitcoin-icon.png")}
                      className="subtract"
                      alt="pic"
                    />
                    <h3>Exceptional Security</h3>
                    <p>
                      Swift execution for seizing market <br />
                      opportunitiesSwift execution for seizing market <br />
                      opportunities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="currency">
          <div class="container-lg">
            <div class="row">
              <div class="col-lg-6">
                <div class="currency-content mt-5">
                  <h1>Available Currencies</h1>
                  <p>
                    There are various crypto currencies available in Taikonz,
                    and youz
                    may easily trade andinvest in all of them.
                  </p>
                  <button
                    class="currency-btn mt-4"
                    onClick={() => navbar("trade/BTC_USDT")}
                  >
                    Explore all
                  </button>
                </div>
              </div>
              <div class="col-lg-6">
                <div className="vertival_scrolls">
                  <Slider {...settings}>
                    {currencylistData &&
                      currencylistData.map((obj, i) => {
                        console.log(obj, "=-=-objobj");
                        return (
                          <>
                            <div>
                              <div class="y-bitcoin1">
                                <div class="y-bitcoin-logo">
                                  <div className="dex_default">
                                    <img
                                      src={obj.Currency_image}
                                      class="img-fluid w-10px"
                                      alt="pic"
                                    />
                                    <div class="y-bitcoin-content">
                                      {" "}
                                      {obj.currencyName}
                                    </div>
                                    <div class="btc">{obj.currencySymbol}</div>
                                  </div>
                                </div>

                                <div class="arrow">
                                  <img
                                    src={require("../../../img/New_images/taikonx/arrow.png")}
                                    class="img-fluid"
                                    alt="pic"
                                  />
                                  <span>{obj.coin_change}%</span>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="investing">
          <div class="container-lg">
            <div class="row">
              <div class="col-lg-12">
                <div class="investing-content mb-5">
                  <h1 class="investing-heading">
                    Investing in crypto will<br /> be 
                    easier than ever.
                  </h1>
                  <p class="investing-text">
                    Taikonz makes it easier to invest and trade in crypto
                    currency than <br />
                    any other platform.
                  </p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="x-investing">
                  <div class="investing-logo">01</div>
                  <div class="x-investing-content">
                    <h3 class="x-investing-heading">Create Account</h3>
                    <p class="x-investing-text">
                      Taikonz has the potential to
                      <br /> execute millions of transactions
                      <br /> every second, and the system.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="x-investing">
                  <div class="investing-logo">02</div>
                  <div class="x-investing-content">
                    <h3 class="x-investing-heading">Add funds</h3>
                    <p class="x-investing-text">
                    The top identity verification <br/>systems complete your KYC<br/> within a few hours.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="x-investing">
                  <div class="investing-logo">03</div>
                  <div class="x-investing-content">
                    <h3 class="x-investing-heading">Start investing</h3>
                    <p class="x-investing-text">
                    The organisation has done all <br/>necessary to make Taikonz the smost secure exchange.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="crypto-platform">
          <div className="container-lg">
            <div className="row">
              <div className="col-lg-7">
                <div className="crypto-platform-content">
                  <h1 className="platform-heading">
                    Crypto Exchange <br />
                    Platform
                  </h1>
                  <p class="platform-text">
                    A next-gen Blockchain and Crypto Exchange platform that
                    offers trader with an endless assets buy and selling feature
                    with real money.
                  </p>
                  <button
                    className="banner-btn mt-4"
                    onClick={() => navbar("trade/BTC_USDT")}
                  >
                    Start Trading
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="footer">
          <div class="container-lg">
            <div class="row">
              <div class="col-lg-4">
                <div class="footer-taikonz">
                  <div className="curspoint">
                    <div class="taikonz-logo">
                      <img
                        onClick={() => navbar("/")}
                        src={require("../../../img/New_images/logo_light.png")}
                      />
                    </div>
                    <div class="dark_taikonz-logo">
                      <img
                        onClick={() => navbar("/")}
                        src={require("../../../img/New_images/logo_dark.png")}
                      />
                    </div>
                  </div>
                  <div class="copyright">{siteData.copy_right_text}</div>
                </div>
              </div>

              <div class="col-lg-8">
                <div class="row">
                  <div class="col-lg-3 col-sm-4">
                    <h4 class="page">Pages</h4>
                    <ul class="list-items">
                      <li onClick={() => navbar("/")}>Privacy Policy</li>
                      <li onClick={() => navbar("/")}>About Us</li>
                      <li onClick={() => navbar("/")}>Contact Us</li>
                    </ul>
                  </div>
                  <div class="col-lg-3 col-sm-4">
                    <h4 class="page">Products</h4>
                    <ul class="list-items">
                      <li onClick={() => navbar("/Stakehome")}>Staking</li>
                      <li onClick={() => navbar("/p2p")}>P2P</li>
                      <li onClick={() => navbar("/launchpadlistnew")}>
                        Launchpad
                      </li>
                      <li onClick={() => navbar("/trade/BTC_USDT")}>Trade</li>
                    </ul>
                  </div>
                  <div class="col-lg-3 col-sm-4">
                    <h4 class="page">Social Media</h4>
                    <ul class="list-items">
                      <a target="_blank" href={siteData.linkedin_url}>
                        <li>LinkedIn</li>
                      </a>
                      <a target="_blank" href={siteData.insta_url}>
                        <li>Instagram</li>
                      </a>
                      <a target="_blank" href={siteData.twitter_url}>
                        <li>Twitter</li>
                      </a>
                      <a target="_blank" href={siteData.fb_url}>
                        <li>Facebook</li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Home;
