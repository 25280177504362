import React, { useState, useEffect } from "react";
// import Header from "./Newcomponent/Header";
import Register_header from "./Newcomponent/Register_header";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { Grid, Paper, Container } from "@mui/material";
import { Button } from "semantic-ui-react";
import useStateref from "react-usestateref";
import { AES, enc } from "crypto-js";
import { v4 as uuid } from "uuid";


const Checkbox = ({ name, isChecked, onChange }) => {
  const clear_name = name.replace(/^(\d+_|\\)(.*)$/g, '$2');
  return (
    <div>
      <label htmlFor={`${name}_id`}>{clear_name}</label>
      <input
        id={`${name}_id`}
        type="checkbox"
        checked={isChecked}
        onChange={e => onChange(e.target.checked)}
      />
    </div>
  );
};


function Home() {
  const [RefferedBy, setRefferedBy] = useState("");

  useEffect(() => {
    var token = localStorage.getItem("user_token");
    if (token && token != undefined) {
      navigate("/Dashboardpage");
    }
    var message = "pavi";
    var secret = "otp";
    const cipherText = AES.encrypt(message, secret);
    console.log(cipherText.toString(), "=--=--=");
    var aa = cipherText.toString();
    var bytes = AES.decrypt(aa, secret);
    const decrypted = bytes.toString(enc.Utf8);
    console.log(decrypted);

    var value = "key";
    var otp =
      localStorage.getItem("^**333**^)") == undefined
        ? ""
        : localStorage.getItem("^**333**^)");
    console.log(otp);
    var bytes = AES.decrypt(otp, value);
    const decrypted_otp = bytes.toString(enc.Utf8);
    console.log("decrypted decrypted", decrypted_otp);

    var currURL = window.location.href;
    var refferalId = currURL.split("invite/")[1];
    setreferral(refferalId);

  }, []);
  var otpName = "^**333**^)";
  const initialFormValue = {
    password: "",
    referral: "",
  };

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [emailValidate, setemailValidate, emailValidateref] = useStateref(
    false
  );
  const [mobileValidate, setmobileValidate] = useState(false);
  const [otpValidate, setotpValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [Email, setEmail, Emailref] = useStateref("");
  const [Mobile, setMobile, Mobileref] = useStateref("");
  const [otp, setotp, otpref] = useStateref("");

  const [step1Value, setstep1Value, step1Valueref] = useStateref(
    localStorage.getItem("step") > 1 ? "step1 d-none" : "step1"
  );
  const [step2Value, setstep2Value, step2Valueref] = useStateref(
    // localStorage.getItem("user_token") ? "step2" :    "step2 d-none"
    localStorage.getItem("step") == 2 && localStorage.getItem("step") != 3
      ? "step2"
      : "step2 d-none"
  );
  const [step3Value, setstep3Value, step3Valueref] = useStateref(
    localStorage.getItem("step") == 3 ? "step3" : "step3 d-none"
  );
  const [step4Value, setstep4Value, step4Valueref] = useStateref(
    "step-4 d-none"
  );
  const [inputType, setinputType] = useState("password");
  const [level1, setlevel1, level1ref] = useStateref("");
  const [level2, setlevel2, level2ref] = useStateref("");
  const [level3, setlevel3, level3ref] = useStateref("");
  const [password, setpassword, passwordref] = useStateref("");
  const [referral, setreferral, referralref] = useStateref("");
  const [termsvalidate, settermsvalidate, termsvalidateref] = useStateref("");

  const otphandle = (value) => {
    console.log(value);
    if (value.toString().length > 4) {
    } else {
      setotp(value);
    }
  }
  const handleChange = async (data) => {
    if (data != "") {
      if (data.match(/[A-Z]/g)) {
        setlevel1("check");
      } else {
        setlevel1("");
      }
      if (data.match(/[0-9]/g)) {
        setlevel2("check");
      } else {
        setlevel2("");
      }
      if (data.length >= 8) {
        setlevel3("check");
        setpassword(data);
      } else {
        setlevel3("");
      }
    } else {
      setlevel3("");
      setlevel2("");
      setlevel1("");
    }
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const validate = async () => {
    const errors = {};
    if (Emailref.current == "" || Emailref.current == undefined) {
      errors.email = "Email is required field";
      setemailValidate(true);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Emailref.current)
    ) {
      errors.email = "Invalid email address";
      setemailValidate(true);
    } else {
      errors.email = "";
      setemailValidate(false);
    }

    if (Mobileref.current == "" || Mobileref.current == undefined) {
      errors.mobile = "Mobile number is required field";
      setmobileValidate(true);
    }
    else {
      errors.mobile = "";
      setmobileValidate(false);
    }
    if (foo == false) {
      errors.terms = "Accept terms and conditions ";
      settermsvalidate(true);
    }
    else {
      errors.terms = "";
      settermsvalidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };
  const validate2 = async () => {
    const errors = {};
    if (otpref.current == "" || otpref.current == undefined) {
      errors.otp = "OTP is required field";
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const resend = async () => {
    var data = {
      apiUrl: apiService.email_otp,
      payload: { email: localStorage.getItem("email") },
    };
    setLoader(true);
    var resp = await postMethod(data);
    setLoader(false);
    if (resp.status == true) {
      var value = "key";
      toast.success(resp.Message);
      const cipherText = AES.encrypt(resp.otp.toString(), value);
      localStorage.setItem(otpName, cipherText.toString());
    } else {
      toast.error(resp.Message);
    }
  };

  const MoveFunction = async (value) => {
    if (value == "step1") {
      validate();
      if (
        Emailref.current != "" &&
        emailValidateref.current == false &&
        Mobileref.current != "" &&
        termsvalidateref.current == false
      ) {
        var data = {
          apiUrl: apiService.email_otp,
          payload: { email: Emailref.current },
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          var value1 = "key";
          setstep1Value("step1 d-none");
          setstep2Value("step2");
          toast.success(resp.Message);
          localStorage.setItem("step", 2);
          localStorage.setItem("email", Emailref.current);
          localStorage.setItem("mobile", Mobileref.current);
          const cipherText = AES.encrypt(resp.otp.toString(), value1);
          localStorage.setItem(otpName, cipherText.toString());
          setEmail("");
          setMobile("");
        } else {
          toast.error(resp.Message);
        }
      }
    } else if (value == "step2") {
      validate2();
      if (otpref.current != "") {
        var value2 = "key";
        var otp = localStorage.getItem("^**333**^)");
        console.log(otp);
        var bytes = AES.decrypt(otp, value2);
        const decrypted = bytes.toString(enc.Utf8);
        if (otpref.current == decrypted) {
          setstep2Value("step2 d-none");
          setstep3Value("step3");
          localStorage.setItem("step", 3);
          toast.success("Verification completed");
          formValue.referral = referralref.current;
        } else {
          toast.error("Invalid OTP");
        }
      }
    } else if (value == "step3") {
      setotp("");
      if (
        level3ref.current != "" &&
        level2ref.current != "" &&
        level1ref.current != ""
      ) {
        setstep3Value("step3 d-none");
        setstep4Value("step4");
      }
    } else if (value == "step4") {
      const unique_id = uuid();
      const small_id = unique_id.slice(0, 8);
      var obj = {
        email: localStorage.getItem("email"),
        mobile: localStorage.getItem("mobile"),
        otp: localStorage.getItem("^**333**^)"),
        password: password,
        referral: referralref.current,
        referralId: small_id,
      };
      var datas = {
        apiUrl: apiService.email_verify,
        payload: obj,
      };
      setbuttonLoader(true);
      var response = await postMethod(datas);
      setbuttonLoader(false);
      if (response.status == true) {
        localStorage.clear();
        navigate("/login");
        toast.success(response.Message);
      }
    }
  };

  const mobilenumhand = (value) => {
    if (value.toString().length > 20) {
    } else {
      setMobile(value);
    }
  };

  const backFun = (data) => {
    if (data == "step2") {
      setstep2Value("step2 d-none");
      setstep1Value("step1");
    } else if (data == "step3") {
      localStorage.clear();
      setstep3Value("step3 d-none");
      setstep1Value("step1");
    }
  };

  const [foo, setFoo] = useState(false);

  return (
    <div className="">
      <main className="main-content tradepage-bg  bg-cover onlywhitee new_login_bb">
        <Register_header />
        <Container maxWidth="xl">
          <Grid container spacing={2} justifyContent={"center"}>
            {/* Item for xs (extra small) screens */}
            <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
              <div className="card_logoki pading_cardd">
                <div className={step1Valueref.current}>
                  <div className="form_content">
                    <h1 className="">
                      Let’s get you started with a Taikonz account!{" "}
                    </h1>
                  </div>
                  <div class="form_login_section p-0">
                    <div class="form register_login p-0">
                      <form className="form_pading_s">
                        <div class="form-group">
                          <label>Email address</label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder="Enter your email Id"
                            name="email"
                            maxLength={50}
                            value={Emailref.current}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div>
                          {" "}
                          {emailValidateref.current == true ? (
                            <p className="text-danger">
                              {" "}
                              {validationnErr.email}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div class="form-group">
                          <label>Mobile number</label>
                          <input
                            type="number"
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder="Enter your mobile number"
                            name="mobile"
                            value={Mobileref.current}
                            onChange={(e) => mobilenumhand(e.target.value)}
                          />
                        </div>
                        <div>
                          {" "}
                          {mobileValidate == true ? (
                            <p className="text-danger">
                              {" "}
                              {validationnErr.mobile}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        
                        <div className="agrreement">
                          <p className="agree">
                            By creating an account, </p>
                            <p className="d-flex agree_check"><input type="checkbox"  isChecked={foo} onChange={setFoo} /> I agree to{" "}
                            <Link to="">Taikonz’s Terms of Service</Link> &{" "}
                            <Link to="">Privacy Policy.</Link>{" "}</p>
                        
                        </div>
                        <div>
                          {termsvalidateref.current == true ?
                            <p className="text-danger">
                              {" "}{validationnErr.terms}{" "}</p> : ""}
                        </div>
                      </form>
                      {buttonLoader == false ? (
                        <button
                          type="button"
                          class="btn btn-primary w-100"
                          onClick={() => MoveFunction("step1")}
                        >
                          Next
                        </button>
                      ) : (
                        <button type="button" class="btn btn-primary w-100">
                          loading...
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className={step2Valueref.current}>
                  <div className="form_content">
                    <Button
                      onClick={() => backFun("step2")}
                      className="back_butn"
                    >
                      <i
                        onClick={() => backFun("step2")}
                        class="ri-arrow-left-line"
                      ></i>{" "}
                      Back
                    </Button>
                    <h1 className="mb-2">Email Verification</h1>
                    <p className="form_lable_botton">
                      Please enter the 6-digit verification code that was sent
                      to emailid@gmail.com. The code is valid for 30 minutes.
                    </p>
                  </div>
                  <div class="form_login_section p-0">
                    <div class="form register_login p-0">
                      <form className="form_pading_s">
                        <div class="form-group">
                          <label>Email verification code</label>
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Enter code"
                            value={otp}
                            onChange={(e) => otphandle(e.target.value)}
                          />
                          <div>
                            {" "}
                            {otpValidate == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr.otp}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </form>
                      <div className="agrreement mt-1">
                        <p>
                          {" "}
                          Didn’t receive a code?{" "}
                          {Loader == true ? (
                            <Link>loading...</Link>
                          ) : (
                            <Link onClick={resend}>Resend</Link>
                          )}{" "}
                          the code. <br />
                        </p>
                      </div>
                      {buttonLoader == false ? (
                        <button
                          type="button"
                          class="btn btn-primary w-100"
                          onClick={() => MoveFunction("step2")}
                        >
                          Submit
                        </button>
                      ) : (
                        <button type="button" class="btn btn-primary w-100">
                          loading...
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className={step3Valueref.current}>
                  <div className="form_content">
                    <Button
                      onClick={() => backFun("step3")}
                      className="back_butn"
                    >
                      <i
                        onClick={() => backFun("step3")}
                        class="ri-arrow-left-line"
                      ></i>{" "}
                      Back
                    </Button>
                    <h1 className="">Set Password</h1>
                  </div>
                  <div class="form_login_section p-0">
                    <div class="form register_login p-0">
                      <form className="form_pading_s">
                        <div class="form-group">
                          <label>Password</label>
                          <div className="postion_reletitt">
                            <input
                              type={inputType}
                              class="form-control"
                              id="exampleInputPassword1"
                              maxLength={40}
                              placeholder="Password"
                              name="password"
                              onChange={(e) => handleChange(e.target.value)}
                            />
                            <div className="input-group-addon">
                              {passHide == false ? (
                                <i
                                  className="bi bi-eye-fill"
                                  onClick={() => passwordHide("hide")}
                                ></i>
                              ) : (
                                <i
                                  className="bi bi-eye-slash-fill"
                                  onClick={() => passwordHide("show")}
                                ></i>
                              )}
                            </div>
                          </div>
                          <div className="agrreement check">
                            <p className={level3ref.current}>
                              <i class="ri-check-line mr-2"></i>At least 8
                              characters
                              <br />
                            </p>
                            <p className={level2ref.current}>
                              <i class="ri-check-line mr-2"></i>At least 1
                              number
                              <br />
                            </p>
                            <p className={level1ref.current}>
                              <i class="ri-check-line mr-2"></i>At least 1 upper
                              case letter
                              <br />
                            </p>
                          </div>
                        </div>
                      </form>

                      {buttonLoader == false ? (
                        <button
                          type="button"
                          class="btn btn-primary w-100"
                          onClick={() => MoveFunction("step3")}
                        >
                          Next
                        </button>
                      ) : (
                        <button type="button" class="btn btn-primary w-100">
                          loading...
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className={step4Valueref.current}>
                  <div className="form_content Proprer_center">
                    <img
                      src={require("../img/New_images/sucess.png")}
                      className="img-fluid "
                    />
                    <h1 className="">Account Created!</h1>
                  </div>
                  <div class="form_login_section p-0">
                    <div class="form register_login p-0">
                      <form className="form_pading_s">
                        <div class="form-group">
                          <label>Referral ID (Optional)</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Referral ID"
                            name="referral"
                            value={referralref.current}
                            onChange={(e) => setreferral(e.target.value)}
                          />
                        </div>
                      </form>

                      {/* {buttonLoader == false ? ( */}
                      <button
                        type="button"
                        class="btn btn-primary w-100"
                        onClick={() => MoveFunction("step4")}
                      >
                        Done
                      </button>
                      {/* ) : ( */}
                      {/* <button type="button" class="btn btn-primary w-100">
                        loading...
                      </button> */}
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          {/* Your other components and content */}
        </Container>
      </main>
    </div>
  );
}

export default Home;
