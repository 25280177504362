import React from 'react';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';

console.log(TradingViewWidget);  // Should not be undefined
console.log(Themes);  // Should not be undefined

const Chart = () => (
  <div style={{ width: '100vw', height: '100vh' }}>
  <TradingViewWidget
    symbol="BINANCE:BTCUSDT"
    theme={Themes.DARK}
    locale="fr"
    autosize
  />
  </div>
);

export default Chart;
